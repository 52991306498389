import { SET_PAGE_CONFIG, SET_IS_MOBILE } from './constants';

export const setPageConfig = config => ({
  type: SET_PAGE_CONFIG,
  config,
});

export const setIsMobile = isMobile => ({
  type: SET_IS_MOBILE,
  isMobile,
});
