import React from 'react';
import classNames from 'classnames';

export const socialLinks = [
  {
    id: 'twitter',
    url: 'https://twitter.com/bbcfood',
  },
  {
    id: 'facebook',
    url: 'https://www.facebook.com/bbcfood',
  },
  {
    id: 'instagram',
    url: 'https://www.instagram.com/bbcfood',
  },
  {
    id: 'pinterest',
    url: 'https://www.pinterest.co.uk/bbcfood',
  },
];

if (process.env.CLIENT) {
  require('./Footer.scss'); // eslint-disable-line global-require
}

const Footer = () => (
  <div className="food-footer__wrap gel-wrap">
    <div className="gel-layout">
      <div className="gel-layout__item">
        <div className="food-footer">
          <div className="gel-body-copy footer__faqs">
            <a href="/food/faqs" className="footer__faqs-link">
              FAQs
            </a>
            <span> about BBC Food</span>
          </div>
          <h2 className="gel-body-copy footer__social-links__title">Find us here</h2>
          <div className="footer__social-links__icon-list">
            {socialLinks.map(({ id, url }) => (
              <a
                key={id}
                className="footer__social-links__link"
                href={url}
                aria-label={`Follow BBC Food on ${id}`}
              >
                <span className={classNames('footer__social-links__icon', id)} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
