import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SecondaryNavList from './SecondaryNavList';
import { NavItemShape } from '../shapes';

export const isPageActive = (activePageId, id, children) =>
  id === activePageId || (children && children.some(({ id: childId }) => childId === activePageId));

export const PrimaryNavList = ({ itemList, activePageId, favouriteHref }) => {
  const [openRecipesSubNav, setOpenRecipesSubNav] = useState(false);

  return (
    <ul className="main-menu__primary-navigation gel-wrap">
      {itemList.map(({ href, id, caption, children }) => (
        <li
          key={id}
          className={classnames([
            'main-menu__item',
            'main-menu__item--primary',
            `main-menu__item--${id}`,
            isPageActive(activePageId, id, children) ? 'main-menu__item--active' : '',
            openRecipesSubNav && id === 'recipes' ? 'main-menu__item--focus' : '',
          ])}
        >
          <a
            aria-label={caption}
            className="main-menu__link gel-brevier"
            href={href}
            onFocus={() => setOpenRecipesSubNav(id === 'recipes')}
          >
            {caption}
            {children && <span className="main-menu__item__icon" />}
          </a>
          {children && <SecondaryNavList itemList={children} activePageId={activePageId} />}
        </li>
      ))}

      <li className="main-menu__item main-menu__item--favorites">
        <a className="main-menu__link gel-brevier" href={favouriteHref}>
          Your Favourites
        </a>
      </li>
    </ul>
  );
};

PrimaryNavList.propTypes = {
  itemList: PropTypes.arrayOf(NavItemShape).isRequired,
  activePageId: PropTypes.string.isRequired,
  favouriteHref: PropTypes.string.isRequired,
};

export default PrimaryNavList;
