import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MobileNavToggleButton from './MobileNavToggleButton';
import PrimaryNavList from './PrimaryNavList';

import { NavItemShape } from '../shapes';

const ampClass = { 'data-amp-bind-class': "'main-menu__navigation ' + navigation.status" };

const MobileNav = ({ activePageId, favouriteHref, itemList, isAMP }) => {
  const [isActive, setIsActive] = useState(isAMP);

  return (
    <div className="main-menu gel-wrap" role="navigation">
      {isAMP && (
        <amp-state key="ampState" id="navigation">
          <script
            type="application/json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify({ status: 'hidden' }) }}
          />
        </amp-state>
      )}
      <MobileNavToggleButton
        isActive={isActive}
        isAMP={isAMP}
        key="mobile-nav-toggle-button"
        onClick={() => setIsActive(!isActive)}
      />
      {isActive && (
        <nav
          className={classNames({
            'main-menu__navigation': true,
            visible: !isAMP && isActive,
            hidden: isAMP || !isActive,
          })}
          {...(isAMP ? ampClass : {})}
        >
          <PrimaryNavList
            activePageId={activePageId}
            favouriteHref={favouriteHref}
            itemList={itemList}
          />
        </nav>
      )}
    </div>
  );
};

MobileNav.propTypes = {
  activePageId: PropTypes.string,
  favouriteHref: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(NavItemShape).isRequired,
  isAMP: PropTypes.bool,
};

MobileNav.defaultProps = {
  activePageId: '',
  isAMP: false,
};

export default MobileNav;
