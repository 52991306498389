import React from 'react';
import PropTypes from 'prop-types';
import { NavItemShape } from '../shapes';
import PrimaryNavList from './PrimaryNavList';

if (process.env.CLIENT) {
  require('./DesktopNav.scss'); // eslint-disable-line global-require
}

const DesktopNav = ({ itemList, activePageId, favouriteHref }) => (
  <div className="main-menu main-menu--full-width">
    <nav className="main-menu__navigation">
      <PrimaryNavList
        itemList={itemList}
        activePageId={activePageId}
        favouriteHref={favouriteHref}
      />
    </nav>
  </div>
);

DesktopNav.propTypes = {
  itemList: PropTypes.arrayOf(NavItemShape).isRequired,
  activePageId: PropTypes.string,
  favouriteHref: PropTypes.string.isRequired,
};

DesktopNav.defaultProps = {
  activePageId: '',
};

export { DesktopNav as default, DesktopNav };
