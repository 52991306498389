import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import Copyright from './Copyright';
import Footer from './Footer';
import Header from './Header';
import Navigation from '../navigation/Navigation';
import SearchBar from '../searchBar/SearchBar';
import PageSetup from './PageSetup';

if (process.env.CLIENT) {
  require('./PageIndex.scss'); // eslint-disable-line global-require
}

export const PageIndex = ({ activePageId, children, isAMP, pageTitle, isMobile }) => (
  <PageSetup>
    <div className="food-body gel-pica">
      <Header activePageId={activePageId} isAMP={isAMP} pageTitle={pageTitle} showBBCLogo={isAMP} />

      <Navigation activePageId={activePageId} isAMP={isAMP} isMobile={isMobile} />

      {!isAMP && activePageId !== 'search' && (
        <div className="gel-wrap">
          <SearchBar className="searchbar--mobile" />
        </div>
      )}

      <div className="food-grid food-grid--full-width">
        <div className="food-content-wrapper">
          {children}
          {isAMP && <Copyright />}

          <div className="food-body__padding-bottom food-body__faqs-background">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </PageSetup>
);

PageIndex.propTypes = {
  activePageId: PropTypes.string,
  children: PropTypes.element.isRequired,
  isAMP: PropTypes.bool,
  pageTitle: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

PageIndex.defaultProps = {
  activePageId: '',
  isAMP: false,
  isMobile: false,
};

export default connect(state =>
  pick(state.pageReducer, ['activePageId', 'isAMP', 'pageTitle', 'isMobile'])
)(PageIndex);
