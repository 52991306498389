import { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { setIsMobile } from './actions';

// Copied from https://github.com/bbc/onesport/blob/master/webapp/static-versioned/js/features.js#L5-L24
/* eslint-disable */
const isTouch = () => {
  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch) ||
    (window.hasOwnProperty &&
      (window.hasOwnProperty('ontouchstart') ||
        (window.DocumentTouch && document instanceof DocumentTouch) ||
        navigator.msMaxTouchPoints))
  ) {
    return true;
  }
  // Fallback for certain samsung devices that fail above but pass below... :/
  if ('ontouchstart' in window) {
    return true;
  }
  return false;
};
/* eslint-enable */

const isMobile = () => process.env.CLIENT && window.innerWidth <= 715;

class PageSetup extends Component {
  componentDidMount() {
    this.props.setIsMobile(isMobile());
    window.addEventListener('resize', this.handleResize);

    if (isTouch()) {
      document.documentElement.classList.remove('no-touch');
      document.documentElement.classList.remove('blocks-touchscreen-disabled');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.props.setIsMobile(isMobile());
  };

  render() {
    return this.props.children;
  }
}

PageSetup.propTypes = {
  children: PropTypes.node.isRequired,
  setIsMobile: PropTypes.func.isRequired,
};

export default connect(null, { setIsMobile })(PageSetup);
