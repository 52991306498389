import React from 'react';
import PropTypes from 'prop-types';
import { NavItemShape } from '../shapes';

if (process.env.CLIENT) {
  require('./SecondaryNavList.scss'); // eslint-disable-line global-require
}

const SecondaryNavList = ({ itemList, activePageId }) => (
  <div className="main-menu__secondary-nav__bg">
    <ul className="main-menu__secondary-navigation gel-wrap">
      {itemList.map(({ href, id, caption }) => (
        <li
          key={id}
          className={`main-menu__secondary-item ${id === activePageId ? 'selected' : ''}`}
        >
          <a aria-label={caption} className="main-menu__secondary-link gel-brevier" href={href}>
            {caption}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

SecondaryNavList.propTypes = {
  itemList: PropTypes.arrayOf(NavItemShape).isRequired,
  activePageId: PropTypes.string.isRequired,
};

export default SecondaryNavList;
