import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchBar from '../searchBar/SearchBar';

if (process.env.CLIENT) {
  require('./Header.scss'); // eslint-disable-line global-require
}

const Header = ({ activePageId, isAMP, showBBCLogo }) => (
  <div className={classNames('gel-wrap', 'page-title', { amp: isAMP })}>
    {showBBCLogo && (
      <a className="food-bbc-logo" href="/">
        BBC
      </a>
    )}
    <div className="header-wrap">
      <a className="page-title__logo" href="/food" title="Food homepage">
        Food
      </a>
      {!isAMP && activePageId !== 'search' && <SearchBar className="searchbar--desktop" />}
    </div>
  </div>
);

Header.propTypes = {
  activePageId: PropTypes.string,
  isAMP: PropTypes.bool,
  showBBCLogo: PropTypes.bool,
};

Header.defaultProps = {
  activePageId: undefined,
  isAMP: false,
  showBBCLogo: false,
};

export default Header;
