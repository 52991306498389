/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./MobileNavToggleButton.scss'); // eslint-disable-line global-require
}

const ampClass = {
  'data-amp-bind-class':
    "'gel-brevier-bold mobile-nav-toggle__button ' + (navigation.status == 'visible' ? 'mobile-nav-toggle__button--active' : '')",
};
const ampOn =
  "tap:AMP.setState({ navigation: { status: navigation.status != 'visible' ? 'visible' : 'hidden' } })";

const MobileNavToggleButton = ({ isActive, isAMP, onClick }) => (
  <div
    className={classNames('gel-layout', 'mobile-nav-toggle', { 'mobile-nav-toggle-amp': isAMP })}
  >
    <div className="gel-layout__item">
      <label htmlFor="mobile-nav-button" className="mobile-nav-toggle__descriptive-text">
        {`${isActive ? 'Close' : 'Open'} All Food menu`}
      </label>
      <button
        className={classNames('gel-brevier-bold', 'mobile-nav-toggle__button', {
          'mobile-nav-toggle__button--active': isActive && !isAMP,
        })}
        {...(isAMP ? ampClass : {})}
        id="mobile-nav-button"
        on={isAMP ? ampOn : undefined}
        onClick={onClick}
        type="button"
      >
        <span className="mobile-nav-toggle__icon" />
        All Food
      </button>
    </div>
  </div>
);

MobileNavToggleButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isAMP: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

MobileNavToggleButton.defaultProps = {
  isAMP: false,
};

export default MobileNavToggleButton;
