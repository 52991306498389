import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SecondaryNavList from './SecondaryNavList';
import { NavItemShape } from '../shapes';
import { isPageActive } from '../desktop/PrimaryNavList';

if (process.env.CLIENT) {
  require('./PrimaryNavList.scss'); // eslint-disable-line global-require
}

export const PrimaryNavList = ({ itemList, activePageId, favouriteHref }) => (
  <ul>
    {itemList.map(({ href, id, caption, children }) => (
      <li
        key={id}
        className={classnames([
          'main-menu__item',
          'main-menu__item--primary',
          `main-menu__item--${id}`,
          isPageActive(activePageId, id, children) ? 'main-menu__item--active' : '',
        ])}
      >
        <a aria-label={caption} className="main-menu__link gel-brevier" href={href}>
          {caption}
        </a>
        {children && <SecondaryNavList itemList={children} activePageId={activePageId} />}
      </li>
    ))}

    <li className="main-menu__item main-menu__item--favorites mobile">
      <a className="main-menu__link gel-brevier" href={favouriteHref}>
        Your Favourites
      </a>
    </li>
  </ul>
);

PrimaryNavList.propTypes = {
  activePageId: PropTypes.string.isRequired,
  favouriteHref: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(NavItemShape).isRequired,
};

export default PrimaryNavList;
