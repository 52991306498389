import React from 'react';
import PropTypes from 'prop-types';
import MobileNav from './mobile/MobileNav';
import DesktopNav from './desktop/DesktopNav';

if (process.env.CLIENT) {
  require('./Navigation.scss'); // eslint-disable-line global-require
}

const itemList = [
  { href: '/food', id: 'home', caption: 'Home' },
  {
    href: '/food/recipes',
    id: 'recipes',
    caption: 'Recipes',
    children: [
      { href: '/food/seasons', id: 'seasons', caption: 'In Season' },
      { href: '/food/occasions', id: 'occasions', caption: 'Occasions' },
      { href: '/food/cuisines', id: 'cuisines', caption: 'Cuisines' },
      { href: '/food/ingredients', id: 'ingredients', caption: 'Ingredients' },
      { href: '/food/dishes', id: 'dishes', caption: 'Dishes' },
      { href: '/food/collections', id: 'collections', caption: 'Collections' },
    ],
  },
  { href: '/food/budget', id: 'budget', caption: 'Budget Recipes' },
  { href: '/food/chefs', id: 'chefs', caption: 'Chefs' },
  { href: '/food/articles', id: 'stories', caption: 'Stories' },
  { href: '/food/diets', id: 'diets', caption: 'Diets' },
  { href: '/food/programmes', id: 'programmes', caption: 'Programmes' },
  { href: '/food/techniques', id: 'techniques', caption: 'Techniques' },
];

const Navigation = ({ activePageId, isAMP, isMobile }) => {
  const NavComponent = isAMP || isMobile ? MobileNav : DesktopNav;

  return (
    <NavComponent
      itemList={itemList}
      isAMP={isAMP}
      activePageId={activePageId}
      favouriteHref="/food/my/favourites"
    />
  );
};

Navigation.propTypes = {
  activePageId: PropTypes.string,
  isAMP: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Navigation.defaultProps = {
  activePageId: undefined,
  isAMP: false,
  isMobile: false,
};

export default Navigation;
