import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavItemShape } from '../shapes';

if (process.env.CLIENT) {
  require('./SecondaryNavList.scss'); // eslint-disable-line global-require
}

const SecondaryNavList = ({ itemList, activePageId }) => (
  <ul className="main-menu__secondary-navigation">
    {itemList.map(({ href, id, caption }) => (
      <li
        key={id}
        className={classNames('main-menu__secondary-item', 'gel-brevier', {
          selected: id === activePageId,
        })}
      >
        <a aria-label={caption} className="main-menu__secondary-link" href={href}>
          {caption}
        </a>
      </li>
    ))}
  </ul>
);

SecondaryNavList.propTypes = {
  itemList: PropTypes.arrayOf(NavItemShape).isRequired,
  activePageId: PropTypes.string.isRequired,
};

export default SecondaryNavList;
