import React from 'react';

if (process.env.CLIENT) {
  require('./Copyright.scss'); // eslint-disable-line global-require
}

const Copyright = () => (
  <div className="copyright">
    <b>Copyright © {new Date().getFullYear()} BBC.</b> The BBC is not responsible for the content
    of external sites.
  </div>
);

export default Copyright;
